<template>
  <div class="setting world">
    <div class="title backgroundWhite marB10">
      <h1 class="h1">换绑手机号</h1>
    </div>
    <div class="setting_content backgroundWhite">
      <div class="content">
        <div class="box">
          <el-form ref="form" :model="form" label-width="200px" class="form" :rules="phonerules">
            <el-form-item label="当前绑定：" class="w350">
              <div class="iphone">{{ iphone }}</div>
            </el-form-item>
            <el-form-item label="更改绑定手机号：">
              <el-input placeholder="请输入手机号" v-model="form.newPhone" @change="getIphone" class="w350">
                <template slot="prepend">+86</template>
              </el-input>
            </el-form-item>
            <el-form-item prop="code" label="短信验证码：">
              <el-input
                  v-model="form.code"
                  placeholder="请输入短信验证码"
                  auto-complete="off"
                  class="w350"
              >
                <template slot="append">
                  <el-button
                      @click="sendCheckCode"
                      class="sendcode"
                      :class="{'disabled-style':getCodeBtnDisable}"
                      :disabled="getCodeBtnDisable"
                  >{{ codeBtnWord }}
                  </el-button>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item>
              <div class="tips" v-if="phoneStatus == 1">
                <div class="tip_content">
                  该手机号码已通过残疾人身份认证，不可绑定企业账号，请您更换手机号。
                </div>
              </div>
              <div class="tips" v-if="phoneStatus == 2">
                <div class="tip_content">
                  该手机号码已关联其他公司账号，需解除绑定，才可绑定当前账号。
                  <router-link :to="'/enterprise/centre/setting/unbind?type=1&phone='+form.newPhone">
                    去解除绑定>>
                  </router-link>
                </div>
              </div>
              <div class="tips" v-if="phoneStatus == 4">
                <!--                注销-->
                <div class="tip_content">
                  该手机号码已被注册为游客账号，需注销账号，才可绑定当前账号。
                  <router-link :to="'/enterprise/centre/setting/unbind?type=2&phone='+form.newPhone">
                    去注销账号>>
                  </router-link>
                </div>
              </div>
              <el-button :type="disabled?'info':'primary'" class="bs_btn_oo w350" @click="modifyMobile('form')"
                         :disabled="disabled">确认修改
              </el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import qs from "qs";

export default {
  name: "setting",
  data() {
    return {
      btntxt: "重新发送",
      codeBtnWord: "获取验证码",
      waitTime: 180,
      i: '<i class="el-icon-d-arrow-right"></i>',
      form: {
        newPhone: "",
        iphone: "",
        code: "",
      },
      disabled: false,
      iphone: "",
      phonerules: {
        newPhone: [
          {required: true, message: "请输入您的手机号", trigger: "blur"},
        ],
        code: [
          {required: true, message: "请输入收到的验证码", trigger: "blur"},
        ],
      },
      phoneStatus: '',
    };
  },
  computed: {
    // 用于校验手机号码格式是否正确
    phoneNumberStyle() {
      let reg = /^1[3456789]\d{9}$/;
      if (!reg.test(this.phone.iphone)) {
        return false;
      }
      return true;
    },
    // 控制获取验证码按钮是否可点击
    getCodeBtnDisable: {
      get() {
        if (this.waitTime == 180) {
          if (this.form.newPhone) {
            return false;
          }
          return true;
        }
        return true;
      },
      set() {
      },
    },
  },
  methods: {
    // 发送验证码
    sendCheckCode() {
      if (!this.form.newPhone) {
        this.$message.error("请输入正确的手机号");
        return;
      } else {
        this.$axios
            .post(
                "/api/app-jycy-phoneverificationcode/phoneVerification/" + this.form.newPhone
            )
            .then((res) => {
              if (res.data.success) {
                this.$message.success({
                  message: "验证码已经发送至您的手机，请注意接收",
                });
              } else {
                this.$message.error({
                  message: res.data.msg,
                });
              }
            });
        let that = this;
        that.waitTime--;
        that.getCodeBtnDisable = true;
        this.codeBtnWord = `${this.waitTime}s 后重新获取`;
        let timer = setInterval(function () {
          if (that.waitTime > 1) {
            that.waitTime--;
            that.codeBtnWord = `${that.waitTime}s 后重新获取`;
          } else {
            clearInterval(timer);
            that.codeBtnWord = "获取验证码";
            that.getCodeBtnDisable = false;
            that.waitTime = 180;
          }
        }, 1000);
      }
    },
    //手机号是否已绑定
    getIphone(val) {
      this.$api.queryMemberTypeByPhoneApi({phone: val}).then(res => {
        if (res.data.success) {
          if (res.data.data == '0') {
            this.disabled = false;
          } else {
            this.disabled = true;
          }
          this.phoneStatus = res.data.data;
        }
      })
    },
    //确认修改手机号
    modifyMobile() {
      if (!this.form.newPhone) {
        this.$message.error("请输入正确的手机号");
        return;
      }
      if (!this.form.code) {
        this.$message.error("请输入收到的验证码");
        return;
      }
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          let params = {
            currentBindPhone: this.iphone,
            changePhone: this.form.newPhone,
            verificationCode: this.form.code,
          };
          let res = await this.$api.getBindPhoneApi(params);
          if (res.data.success) {
            this.$message.success(res.data.msg);
            this.getUserInfo();
            this.form = {};
          } else {
            this.$message.error(res.data.msg);
          }
          console.log("修改手机号", res);
        }
      });
    },
    // 查询当前手机号
    getUserInfo() {
      let that = this;
      that.$api.getTel().then((res) => {
        if (!res.data.success) {
          this.iphone = "暂未绑定";
        } else {
          if (res.data.msg == null || res.data.msg == "") {
            this.iphone = "暂未绑定";
          } else {
            console.log(res);
            this.iphone = res.data.msg;
          }
        }
      });
    },
  },
  created() {
    this.getUserInfo();
  },
};
</script>

<style scoped lang="less">
@import "~assets/css/setting.less";

.el_slider__runway {
  text-align: center;
  line-height: 42px;
}

.sendcode {
  // margin-left: -29px;
  font-size: 14px;
}

/deep/ .el-input-group__append {
  background: #ffffff;
  color: #00924c;
  // border: 1px solid #00924c;
}

.tips {
  width: 350px;
  margin: 20px 0;

  .tip_content {
    line-height: 1.8em;
    color: #666666;

    a {
      color: #28A46D;
    }
  }
}
</style>
